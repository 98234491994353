:root {
  --font: 'Inter', sans-serif;
  --subfont: 'Quicksand', sans-serif;

  --primary: #292d32;
  --primary-light: #6d7280;
  --primary-dark: #0a2540;

  --secondary: #9b59b6;
  --secondary-light: #e4cdee;
  --secondary-dark: #8e44ad;

  --info: #3796f6;
  --info-light: #bfdbf6;
  --info-dark: #0b7cef;

  --success: rgba(68, 143, 41, 1);
  --success-light: rgba(98, 163, 73, 1);
  --success-dark: #388e3c;

  --error: rgba(233, 44, 44, 1);
  --error-light: #f5caca;
  --error-dark: #d32f2f;

  --warning: #ff7559;
  --warning-light: #faebd6;
  --warning-dark: #f57c00;

  --light: #ebf3fa;
  --dark: #212529;

  --white: #ffffff;
  --black: #000000;
  --gray: #e2e2e2;
  --purple: #453fa4;

  --container: 1600px;
  --small-container: 890px;
  --transition-duration: 0.3s;
}
