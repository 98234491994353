@import 'reset';
@import 'vars';
@import 'helpers';
@import 'mixins';

body {
  font-family: var(--font);
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  color: var(--primary-light);
  background: #0c0c0c;
  @include scrollbar(8px, #595778, var(--light), 5px, 2px);
  padding-top: 97px;

  @media (max-width: 991px) {
    padding-top: 66px;
  }
}

a {
  display: flex;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
}

button {
  color: var(--primary);
  -webkit-tap-highlight-color: transparent;
}

#root {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
}

.page-wrap {
  flex: 1;
  background-color: var(--light);
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 32px;
  padding-right: 32px;
  max-width: var(--container);
  width: 100%;

  @media (max-width: 991px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.card-wrap {
  padding: 24px;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff;
}

h1,
.h1 {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.4px;
  color: #fff;
}

h2,
.h2,
h3,
.h3 {
  font-size: 24px;
  line-height: 24px;
  font-weight: 600;
}

h3,
.h3 {
  font-size: 18px;
  font-weight: 600;
}

.backlink {
  font-size: 10px;
  line-height: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--info);

  &:hover {
    opacity: 0.7;
  }

  img {
    margin-right: 8px;
  }
}

//Page blocks

.block {
  &__title {
    font-family: var(--subfont);
    font-weight: 500;
    font-size: 40px;
    line-height: 50px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #200d27;
  }
}

// Table
.table {
  tr {
    border-bottom: 1px solid var(--light);
  }

  th {
    vertical-align: middle;
    padding: 8px 16px;
    height: 50px;
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    color: var(--info);
  }

  td {
    vertical-align: middle;
    padding: 8px 16px;
    height: 60px;
  }
}

.table-inner {
  display: flex;
  gap: 16px;

  &--right {
    justify-content: right;
  }

  &--center {
    justify-content: center;
  }
}

// Helper classes
.flex {
  display: flex;
  flex-wrap: wrap;

  &--column {
    flex-direction: column;
  }
}

.no-wrap {
  flex-wrap: nowrap;
}

.gap-25 {
  gap: 25px;
}

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.full-width {
  width: 100%;
}

.good {
  color: var(--success);
}

.alert {
  color: var(--error);
}

.warning {
  color: var(--warning);
}

.bold {
  font-weight: 700;
}

.break-word {
  overflow-wrap: break-word;
}

.lowercase {
  text-transform: lowercase;
}
